<template>
  <div>
    <canvas id="header-canvas"> </canvas>
    <div id="header" class="text-center">
      <v-img
        class="shrink mr-2 d-sm-block d-none"
        contain
        :src="require('@/assets/yonder-logo-y-white.svg')"
      />

      <div class="title d-sm-block d-none">
        <div>
          <h1 style="padding-top: 40px; margin-bottom: 12px">onder Dynamics</h1>
        </div>
        <h3>Dream Bigger</h3>
      </div>

      <img
        id="curiosity"
        src="@/assets/complacency.svg"
        class="d-sm-block d-none"
      />

      <div class="d-flex d-sm-none">
        <img id="main-logo" class="yonder-logo-y-white" />
        <div class="title">
          <div>
            <h1 style="padding-top: 20px">Yonder Dynamics</h1>
          </div>
          <h3>Dream Bigger</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LandingSplash',

  mounted() {
    const background = document.getElementById('header-canvas');
    const bgCtx = background.getContext('2d');
    const width = background.offsetWidth;
    const height = background.offsetHeight;

    background.width = width;
    background.height = height;

    // stars
    function Star(options) {
      this.size = Math.random() * 2;
      this.speed = Math.random() * 0.0003 + 0.0001;
      this.centerPointX = options.centerPointX;
      this.centerPointY = options.centerPointY;
      this.angle = options.angle;
      this.length = options.length;
    }

    Star.prototype.update = function () {
      this.angle += this.speed;
      if (this.angle > (Math.PI * 7) / 4) {
        this.angle = (Math.PI * 5) / 4;
      } else {
        const x = this.centerPointX + Math.cos(this.angle) * this.length;
        const y = this.centerPointY + Math.sin(this.angle) * this.length;
        bgCtx.fillRect(x, y, this.size, this.size);
      }
    };

    function ShootingStar() {
      this.reset();
    }

    // eslint-disable-next-line func-names
    ShootingStar.prototype.reset = function () {
      this.x = Math.random() * width;
      this.y = 0;
      const len = Math.random() * 80 + 10;
      const speed = Math.random() * 10 + 6;
      const angle = (Math.random() * Math.PI) / 4 + Math.PI / 8;
      this.deltaX = Math.cos(angle) * speed;
      this.deltaY = Math.sin(angle) * speed;
      this.xLen = -Math.cos(angle) * len;
      this.yLen = -Math.sin(angle) * len;
      this.size = Math.random() * 1 + 0.1;
      // this is used so the shooting stars arent constant
      this.waitTime = new Date().getTime() + Math.random() * 3000 + 500;
      this.active = false;
    };

    // eslint-disable-next-line func-names
    ShootingStar.prototype.update = function () {
      if (this.active) {
        this.x += this.deltaX;
        this.y += this.deltaY;
        if (this.x < 0 || this.y >= height) {
          this.reset();
        } else {
          bgCtx.lineWidth = this.size;
          bgCtx.beginPath();
          bgCtx.moveTo(this.x, this.y);
          bgCtx.lineTo(this.x + this.xLen, this.y + this.yLen);
          bgCtx.stroke();
        }
      } else if (this.waitTime < new Date().getTime()) {
        this.active = true;
      }
    };

    const entities = [];

    // init the stars
    /* eslint-disable operator-linebreak */
    for (let i = 0; i < height; i += 1) {
      entities.push(
        new Star({
          centerPointY: height + width / 2,
          centerPointX: width / 2,
          angle: (Math.random() * Math.PI) / 2 + (Math.PI * 5) / 4,
          length:
            Math.random() *
              ((width / 2 + height) ** 2 + (width / 2) ** 2) ** 0.5 +
            width / 2,
        }),
      );
    }

    entities.push(new ShootingStar());
    entities.push(new ShootingStar());

    function animate() {
      bgCtx.fillStyle = '#2D2542';
      bgCtx.fillRect(0, 0, width, height);
      bgCtx.fillStyle = '#ffffff';
      bgCtx.strokeStyle = '#ffffff';

      entities.forEach((entitiy) => {
        entitiy.update();
      });

      bgCtx.fillStyle = '#1F1A2E';
      bgCtx.strokeStyle = '#1F1A2E';
      bgCtx.beginPath();
      bgCtx.ellipse(
        width / 2,
        height,
        (width * 3) / 4,
        height / 4,
        0,
        0,
        2 * Math.PI,
      );
      bgCtx.fill();

      requestAnimationFrame(animate);
    }
    animate();
  },
};
</script>

<style scoped>
#main-logo {
  height: 154px;
  z-index: 1;
}
.title {
  margin-left: -15px;
}

#curiosity {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 432px;
  width: 432px;
}

@media only screen and (max-height: 850px) {
  #curiosity {
    bottom: -50px;
    right: 0;
  }
}

@media only screen and (max-height: 600px) {
  #curiosity {
    bottom: -90px;
    right: 0;
  }
}

h3 {
  font-size: 28px;
}

#header {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  z-index: 2;
}
#header-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}
</style>
