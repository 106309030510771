<template>
  <Section medium>
    <div class="footer-wrapper">
      <p class="footer">&copy; Yonder Dynamics, 2024</p>
    </div>
    <v-spacer />
    <div class="footer-wrapper">
      <a href="mailto:yonder.dynamics@ucsd.com" class="footer">
        <p class="footer">yonder.dynamics@ucsd.com</p>
      </a>
    </div>
  </Section>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Footer',

  components: {
    Section,
  },
};
</script>

<style scoped>
.footer-wrapper {
  margin-left: 10%;
  margin-right: 10%;
}
.footer {
  margin-bottom: 0px !important;
  color: white;
  font-size: 20px;
}
a {
  text-decoration: none;
}
</style>
