<template>
  <div
    id="rocket-row"
    style="width: 100%; position: relative; overflow: hidden"
  >
    <div id="rocket">
      <div>
        <img id="rocket-img" src="@/assets/rocket.svg" />
      </div>
      <div>
        <svg
          id="fire"
          height="100"
          width="100"
          viewbox="0,0,100,100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            id="fire-poly"
            stroke="red"
            fill="red"
            points="20,20 30,100 60,95 60,60 80,0"
          />
        </svg>
      </div>
    </div>
    <div id="launchpad">
      <img id="launchpad" src="@/assets/launchpad.png" />
    </div>
  </div>
</template>

<script>
import anime from 'animejs';

export default {
  name: 'Rocket',

  mounted() {
    const width = 50;

    const rocketAnimation = anime({
      targets: '#rocket',
      keyframes: [
        { translateX: width / 2, rotate: -5 },
        { translateX: 0, rotate: 0 },
        { translateX: -width / 2, rotate: 5 },
        { translateX: 0, rotate: 0 },
      ],
      easing: 'linear',
      loop: true,
      autoplay: false,
    });

    const fireAnimation = anime({
      targets: '#fire-poly',
      points: [
        { value: '20,0 23,80 40,70 55,100 90,0' },
        { value: '20,4 50,89 70,100 78,0 60,24' },
        { value: '20,0 0,80 70,100 55,55 90,0' },
        { value: '20,0 45,55 60,100 100,68 85,5' },
        { value: '20,20 30,100 60,95 60,60 80,0' },
      ],
      easing: 'easeOutQuad',
      autoplay: false,
      loop: false,
    });

    const launchpadAnimation = anime({
      targets: '#launchpad',
      translateY: 50,
      easing: 'linear',
      autoplay: false,
      loop: false,
    });

    const rowHeight = 400;
    const numLoops = 2;
    const numFireLoops = 2;

    document.addEventListener('scroll', () => {
      const viewportOffset = document
        .getElementById('rocket-row')
        .getBoundingClientRect();
      // eslint-disable-next-line operator-linebreak
      const ratio =
        (viewportOffset.top + rowHeight) / (window.screen.height + rowHeight);
      // eslint-disable-next-line operator-linebreak
      const ratioLaunchpad =
        (viewportOffset.top + rowHeight) / (window.screen.height + 100);
      if (ratio > 0 && ratio < 1) {
        rocketAnimation.seek(
          (((1 - ratio) * numLoops) % 1) * rocketAnimation.duration,
        );
        fireAnimation.seek(
          (((1 - ratio) * numFireLoops) % 1) * fireAnimation.duration,
        );
      }
      if (ratioLaunchpad > 0 && ratioLaunchpad < 1) {
        launchpadAnimation.seek(
          ((1 - ratioLaunchpad) % 1) * launchpadAnimation.duration,
        );
      }
    });
  },
};
</script>

<style scoped>
#rocket {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: 30px;
  position: absolute;
}

#rocket-img {
  height: 100px;
}
#fire {
  height: 50px;
  width: 50px;
  margin-left: -15px;
}

#launchpad {
  height: 100px;
  position: absolute;
  top: 150px;
  left: 50%;
}

#rocket-row {
  height: 400px;
  margin: 0 !important;
  background-color: var(--gray);
}
</style>
