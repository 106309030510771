<template>
  <div
    :dark="!gray"
    :class="{
      'medium-background': medium,
      'light-background': light,
      'gray-background': gray,
    }"
  >
    <v-container class="main-continer">
      <v-row>
        <slot></slot>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Section',

  props: {
    medium: {
      default: false,
      type: Boolean,
    },
    light: {
      default: false,
      type: Boolean,
    },
    gray: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.section {
  color: white;
}
.medium-background {
  background-color: var(--medium);
}
.light-background {
  background-color: var(--light);
}
.gray-background {
  background-color: var(--gray);
}
h1 {
  text-align: center;
  width: 100%;
}
.main-continer {
  overflow: auto;
}
</style>
