import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/mechanical',
    name: 'Mechanical',
    component: () => import('../views/Mechanical.vue'),
  },
  {
    path: '/science',
    name: 'Science',
    component: () => import('../views/Science.vue'),
  },
  {
    path: '/electrical',
    name: 'Electrical',
    component: () => import('../views/Electrical.vue'),
  },
  {
    path: '/software',
    name: 'Software',
    component: () => import('../views/Software.vue'),
  },
  {
    path: '/members',
    name: 'Members',
    component: () => import('../views/Members.vue'),
  },
  {
    path: '/apply',
    name: 'Apply',
    component: () => import('../views/Apply.vue'),
  },
  {
    path: '/donate',
    name: 'Donate',
    component: () => import('../views/Donate.vue'),
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
