<template>
  <Section :light="light" :medium="medium" :gray="gray">
    <v-col class="col-sm-6">
      <slot name="left"></slot>
    </v-col>
    <v-col class="col-sm-6">
      <slot name="right"></slot>
    </v-col>
  </Section>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'SplitSection',

  props: {
    medium: {
      default: false,
      type: Boolean,
    },
    light: {
      default: false,
      type: Boolean,
    },
    gray: {
      default: false,
      type: Boolean,
    },
  },

  components: {
    Section,
  },
};
</script>
