<template>
  <Section gray>
    <v-col class="col-lg-10 offset-lg-1">
      <v-container>
        <v-row justify="center">
          <v-col cols="12">
            <h1>Thanks to our sponsors!</h1>
          </v-col>
          <v-col class="col-md-6 col-sm-6">
            <a href="https://www.digikey.com/" target="_blank"
              ><v-img src="@/assets/sponsors/digikey.png"
            /></a>
          </v-col>
          <v-col class="col-md-6 col-sm-6">
            <a href="https://www.thorlabs.com/" target="_blank"
              ><v-img src="@/assets/sponsors/thorlabs.png"
            /></a>
          </v-col>
          <v-col class="col-md-6 col-sm-6">
            <a href="https://www.intellisurvey.com/" target="_blank"
              ><v-img src="@/assets/sponsors/intellisurvey.png"
            /></a>
          </v-col>
          <v-col class="col-md-6 col-sm-6">
            <a href="https://www.altair.com/" target="_blank"
              ><v-img src="@/assets/sponsors/altair.png"
            /></a>
          </v-col>

          <v-col class="col-md-6 col-sm-6">
            <a href="http://jacobsschool.ucsd.edu/idea/" target="_blank"
              ><v-img src="@/assets/sponsors/ideaengineering.png"
            /></a>
          </v-col>
          <v-col class="col-md-6 col-sm-6">
            <a href="http://https://www.protocase.com/" target="_blank"
              ><v-img src="@/assets/sponsors/protocase.png"
            /></a>
          </v-col>
          <div class="col-sm-12" style="margin-top: 25px; margin-bottom: 15px">
            <h1>And to our crowdfunding backers!</h1>
          </div>
          <h3 class="mb-6">
            <span
              class="px-3"
              style="white-space: nowrap; display: inline-block"
              v-for="name of this.indie"
              :key="name"
              >{{ name }}</span
            >
          </h3>
        </v-row>
      </v-container>
    </v-col>
  </Section>
</template>

<script>
import Section from '@/components/Section.vue';

export default {
  name: 'Footer',

  components: {
    Section,
  },
  created() {
    this.indie = [
      'Alexander Haggart',
      'Vaishali Patil',
      'Cyrus Cowley',
      'Alex Smith',
      'Luke Piszkin',
      'Brandon Ho',
      'Andrea Pabst',
      'Robin Wang',
      'Lucas Wang',
      'Joseph Payne',
      'Allison Kubo',
    ];
  },
};
</script>

<style scoped>
h1,
h3 {
  color: black;
}
h3 {
  font-size: 28px;
  text-align: center;
}
</style>
