import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'medium-background': _vm.medium,
    'light-background': _vm.light,
    'gray-background': _vm.gray,
  },attrs:{"dark":!_vm.gray}},[_c(VContainer,{staticClass:"main-continer"},[_c(VRow,[_vm._t("default")],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }